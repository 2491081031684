<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Você deseja cancelar esta proposta?
            </h5>
            <button
              type="button"
              class="btn btn-danger btn-sm"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <span
              >O cancelamento da proposta será imediato e ela não podera ser
              retomada.</span
            >
          </div>
          <div class="modal-footer">
            <div class="d-flex">
              <div>
                <button
                  class="btn btn-outline-secondary"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                >
                  Voltar
                </button>
              </div>
              <div>
                <button
                  class="btn btn-danger ml-3"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  @click="cancelProposal()"
                >
                  Cancelar proposta
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   <SummaryModal  @createProposal="createProposal()" @closeModal="modalOpen = false"
    v-if="modalOpen" class="sumary-modal" 
      :selectedChannel="opportunitie.sales_channel"
      :couponAddonChip="coupon_validate_addon_chip.valid"
      :not_extra_chips="not_extra_chips"
      :subtitle="SummaryModal.subtitle" 
      :title="SummaryModal.title" :opportunity="opportunitie" 
      :planSelected="planSelected"  
      :totalUsers="opportunitie.extraDatas.users"
      :implantation_tec="implantation_tec"
      :implantation="implantation"
      :pacakgeMessage="pacakgeMessage"
      :integrationTallos="selectedMessage === 'tallos'"
      :extraUsers="users_qty_selected"
      :couponAddonUser= "coupon_validate_addon_user.valid"
      :couponProposal="validateCouponPlan"
      :couponAddons="validateCouponAddon"
      :focalEmailProject="opportunitie.extraDatas.focal_email_project"
      :financeEmail="opportunitie.extraDatas.focal_email_finance"
      :indicationSelected="indicationSelected"

      :addon_chip_selected="asChips"
      :planCoupon="offPlan" 
      :addons="[{addon: users_qty_selected, coupon: coupon_validate_addon_user},
                {addon: addon_chip_selected, coupon: coupon_validate_addon_chip},
                {addon: implantation ? billingOnboard[0] : {}, coupon: offAddon},
                {addon: implantation_tec ? billingOnboard[1]: {}, coupon: offAddon}]"
      />
    <TitlePage :title="titlePage" :iconClass="iconClass" />
    <!--Form pt 1-->
    <div  v-if="rulesAssociatedSales">
            <ChannelSelect
            :disableInputs="disableFields"
            :selectedChannel="opportunitie.sales_channel" 
            :opportunity="opportunitie"
            @update-channel="selectChannel"
            />
          </div>
    <form ref="myForm" @submit.prevent>
    <div class="row" ref="form">
      <div class="col-md-6">
        <section class="content">
          <div class="col-md-12" id="NRC">
            
            <div class="d-flex flex-column col-12">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="cnpj">1. CNPJ Cliente</label>
              </div>
              <div class="form-group col-lg-12">
                <input
                  @blur="getCnpj(opportunitie.document, '1000000')"
                  required
                  v-mask="'##.###.###/####-##'"
                  v-model="opportunitie.document"
                  type="string"
                  name="cnpj"
                  id="cnpj"
                  class="form-control"
                  placeholder="00.000.000/0001-00"
                  :disabled="disableFields"
                />
              </div>
            </div>
            <div class="d-flex flex-column col-12">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="company-name"
                  >Razão Social do Cliente</label
                >
              </div>
              <div class="form-group col-lg-12">
                <input
                  required
                  v-model="opportunitie.legal_name"
                  type="text"
                  id="company-name"
                  name="company-name"
                  class="form-control"
                  placeholder="Razão Social"
                  :disabled="disableFields || cnpjIsValid"
                />
              </div>
            </div>
            <div class="d-flex flex-column col-12 mt-2">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="trading-name"
                  >Nome Fantasia</label
                >
              </div>
              <div class="form-group col-lg-12">
                <input
                  required
                  v-model="opportunitie.name"
                  type="text"
                  id="trading-name"
                  name="trading-name"
                  class="form-control"
                  placeholder="Nome Fantasia"
                  :disabled="disableFields || cnpjIsValid"
                />
              </div>
            </div>
            <div class="d-flex flex-column col-12 mt-2">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="represent-name"
                  >Nome do representante legal</label
                >
              </div>
              <div class="form-group col-lg-12">
                <input
                  required
                  v-model="opportunitie.represent_legal_name"
                  type="text"
                  id="represent-name"
                  name="represent-name"
                  class="form-control"
                  placeholder="Nome do representante legal"
                  :disabled="disableFields"
                />
              </div>
            </div>
            <div class="d-flex flex-column col-12">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="email">Email do representante legal</label>
              </div>
              <div class="form-group col-lg-12">
                <input
                required
                @change="validateEmail($event)"
                @blur="validateEmail($event)"
                v-model="opportunitie.email"
                type="email"
                name="email"
                id="email"
                class="form-control"
                placeholder="email@email.com"
                :disabled="disableFields"
                />
                <span class="info-acess">Os acessos serão enviados para esse email.</span>
              </div>
            </div>
            <div class="d-flex flex-column col-12">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="cellphone"
                  >Telefone/ Whatsapp representante legal</label
                >
              </div>
              <div class="form-group col-lg-12">
                <vue-tel-input
                    @country-changed="getCountry($event)"
                    @validate="countryIsValid($event)"
                    defaultCountry="BR"
                    v-model="opportunitie.mobile"
                    class="form-control"
                    :class="!disableFields ? countryValid ? 'number-valid' : 'number-invalid' : ''"
                    :inputOptions="{
                      placeholder: 'Insira o numero',
                    }"
                    :dropdownOptions="{
                      showFlags: !disableFields
                    }"
                    :disabled="disableFields"
                  >
                    <template v-slot:arrow-icon>
                      <span>{{ '▼' }}</span>
                    </template>
                  </vue-tel-input>
                </div>
            </div>
            <div class="d-flex flex-column col-12 mt-4">
              <label>Endereço</label>
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="zipcode">CEP</label>
              </div>
              <div class="form-group col-lg-12">
                <input
                  @blur="searchAdress()"
                  v-model="opportunitie.address.zip_code"
                  type="zipcode"
                  name="zipcode"
                  id="zipcode"
                  class="form-control"
                  placeholder="00-000-000"
                  :disabled="disableZipCode"
                />
              </div>
            </div>
            <div class="d-flex flex-column col-12">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="district">Bairro</label>
              </div>
              <div class="form-group col-lg-12">
                <input
                  v-model="opportunitie.address.district"
                  type="text"
                  name="district"
                  id="district"
                  class="form-control"
                  placeholder="Bairro"
                  :disabled="disableDistrict"
                />
              </div>
            </div>
            <div class="d-flex flex-column col-12">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="street">Rua</label>
              </div>
              <div class="form-group col-lg-12">
                <input
                  v-model="opportunitie.address.street"
                  type="text"
                  name="street"
                  id="street"
                  class="form-control"
                  placeholder="Rua"
                  :disabled="disableStreet"
                />
              </div>
            </div>
            <div class="d-flex flex-column col-12">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="number-street">Número</label>
              </div>
              <div class="form-group col-lg-12">
                <input
                  v-model="opportunitie.address.number"
                  type="number"
                  name="number-street"
                  id="number-street"
                  class="form-control"
                  placeholder="000"
                  :disabled="disableNumber"
                />
              </div>
            </div>
            <div class="d-flex flex-column col-12">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="complementary"
                  >Complemento</label
                >
              </div>
              <div class="form-group col-lg-12">
                <input
                  v-model="opportunitie.address.complement"
                  type="text"
                  name="complementary"
                  id="complementary"
                  class="form-control"
                  placeholder="apt 000"
                  :disabled="disableComplement"
                />
              </div>
            </div>
            <div class="d-flex col-12">
              <div>
                <div
                  class="label d-flex flex-row justify-content-start col-lg-12"
                >
                  <label class="word-custom" for="city">Cidade</label>
                </div>
                <div class="form-group col-lg-12">
                  <input
                    v-model="opportunitie.address.city"
                    type="text"
                    name="city"
                    id="city"
                    class="form-control"
                    placeholder="Cidade"
                    :disabled="disableCity"
                  />
                </div>
              </div>
              <div>
                <div
                  class="label d-flex flex-row justify-content-start col-lg-12"
                >
                  <label class="word-custom" for="state">Estado</label>
                </div>
                <div class="form-group col-lg-12">
                  <input
                    v-model="opportunitie.address.state"
                    type="text"
                    name="state"
                    id="state"
                    class="form-control"
                    placeholder="Estado"
                    :disabled="disableState"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex flex-column col-8">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="cellphone">Telefone</label>
              </div>
              <div class="form-group col-lg-12">
                <vue-tel-input
                    @country-changed="setPhonePrefix($event)"
                    @validate="PhoneIsValid($event)"
                    defaultCountry="BR"
                    :class="!disableFields ? phoneValid ? 'number-valid' : 'number-invalid': ''"
                    v-model="opportunitie.phone"
                    class="form-control"
                    :inputOptions="{
                      placeholder: 'Insira o numero',
                    }"
                    :dropdownOptions="{
                      showFlags: !disableFields
                    }"
                    :disabled="disableFields"
                  >
                    <template v-slot:arrow-icon>
                      <span>{{ '▼' }}</span>
                    </template>
                  </vue-tel-input>
              </div>
            </div>
          </div>

          <hr />
        </section>
      </div>
      <div class="col-md-6">
        <!-- Form pt 2-->
        <section class="content">
          <div class="col-md-12" id="NRC">
            <div class="d-flex flex-column col-12 mt-4">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="focal-name"
                  >2. Nome Ponto focal financeiro</label
                >
              </div>
              <div class="form-group col-lg-12">
                <input
                  v-model="opportunitie.extraDatas.focal_name"
                  type="text"
                  id="focal-name"
                  name="focal-name"
                  class="form-control"
                  placeholder="Digite o nome focal financeiro"
                  :disabled="disableFields"
                />
              </div>
            </div>

            <div class="d-flex flex-column col-12">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="focal-cellphone"
                  >Telefone/ Whatsapp do Ponto focal financeiro</label
                >
              </div>
              <div class="form-group col-lg-12">
                <vue-tel-input
                    @country-changed="setFocalPhoneFinancePrefix($event)"
                    @validate="FocalPhoneFinanceIsValid($event)"
                    defaultCountry="BR"
                    v-model="opportunitie.extraDatas.focal_phone_finance"
                    :class="!disableFields ? FocalPhoneFinanceValid ? 'number-valid' : 'number-invalid' : ''"
                    class="form-control"
                    :inputOptions="{
                      placeholder: 'Telefone do financeiro',
                    }"
                    :dropdownOptions="{
                      showFlags: !disableFields
                    }"
                    :disabled="disableFields"
                  >
                    <template v-slot:arrow-icon>
                      <span>{{ '▼' }}</span>
                    </template>
                  </vue-tel-input>
              </div>
            </div>
            <div class="d-flex flex-column col-12">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="email_finance"
                  >Email do ponto focal do financeiro</label
                >
              </div>
              <div class="form-group col-lg-12">
                <input
                  @change="validateEmail($event)"
                  @blur="validateEmail($event)"
                  v-model="opportunitie.extraDatas.focal_email_finance"
                  type="email"
                  name="email_finance"
                  id="email_finance"
                  class="form-control"
                  placeholder="Email do financeiro"
                  :disabled="disableFields"
                />
              </div>
            </div>
            <div class="d-flex flex-column col-12 mt-4">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="focal-name"
                  >3. Nome Ponto focal projeto</label
                >
              </div>
              <div class="form-group col-lg-12">
                <input
                  v-model="opportunitie.extraDatas.focal_name_project"
                  type="text"
                  id="focal-name"
                  name="focal-name"
                  class="form-control"
                  placeholder="Digite o nome focal projeto"
                  :disabled="disableFields"
                />
              </div>
            </div>

            <div class="d-flex flex-column col-12">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="focal-cellphone"
                  >Telefone/ Whatsapp do Ponto focal projeto</label
                >
              </div>
              <div class="form-group col-lg-12">
                <vue-tel-input
                    @country-changed="setFocalPhoneProjectPrefix($event)"
                    @validate="FocalPhoneProjectIsValid($event)"
                    defaultCountry="BR"
                    v-model="opportunitie.extraDatas.focal_phone_project"
                    class="form-control"
                    :class="!disableFields ? FocalPhoneProjectValid ? 'number-valid' : 'number-invalid' : ''"
                    :inputOptions="{
                      placeholder: 'Telefone do ponto focal do projeto',
                    }"
                    :dropdownOptions="{
                      showFlags: !disableFields
                    }"
                    :disabled="disableFields"
                  >
                    <template v-slot:arrow-icon>
                      <span>{{ '▼' }}</span>
                    </template>
                  </vue-tel-input>
              </div>
            </div>
            <div class="d-flex flex-column col-12">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="email_project"
                  >Email do ponto focal do projeto</label
                >
              </div>
              <div class="form-group col-lg-12">
                <input
                  @change="validateEmail($event)"
                  @blur="validateEmail($event)"
                  v-model="opportunitie.extraDatas.focal_email_project"
                  type="email"
                  name="email_project"
                  id="email_project"
                  class="form-control"
                  placeholder="Email do representante do projeto"
                  :disabled="disableFields"
                />
              </div>
            </div>
          <div class="d-flex flex-column col-12 mt-4" v-if="rulesAssociatedSales && associateSaleSelected">
            <div class="label d-flex justify-content-start">
                  <label class="word-custom" for="sales_partner_type">4. Canal de revenda</label>
                </div>
                <div class="form-group col-lg-12">
                  <select type="text" id="sales_partner_type" name="sales_partner_type" class="form-control"
                    placeholder="Selecione o canal de revenda" :disabled="disableFields" v-model="partnerIndicationSelected">
                    <option v-for="(item, index) in partnerIndication" :value="item.value" :key="index">
                      {{ item.label }}
                    </option>
                  </select>
                </div>
                <div class="label d-flex justify-content-start">
                  <label class="word-custom" for="sales_indication">Indicação Parceiro</label>
                </div>
                <div class="form-group col-lg-12">
                  <input type="text" id="sales_indication" name="sales_indication" class="form-control"
                    v-model="partnerFilter" placeholder="Digite o nome da empresa que está indicando" :disabled="disableFields || !partnerIndicationSelected"
                    @keypress="typingPartner()">
                  <div class="listPartners" v-if="isTypingPartner">
                    <div class="partner-list">
                      <option v-for="partner in indicationPartner" :value="partner" :key="partner.id"
                        class="partnersValue" @click="selectPartner(partner)">
                        {{ partner.id }} - {{ partner.name }}
                      </option>
                    </div>
                  </div>
                </div>
          </div>
          <div v-else class="d-flex flex-column col-12 mt-4">
            <div class="label d-flex justify-content-start">
              <label class="word-custom" for="sales_indication">4. Indicação</label>
            </div>
            <div class="form-group col-lg-12">
              <select
                type="text"
                id="sales_indication"
                name="sales_indication"
                class="form-control"
                placeholder="TOVS ou RD"
                v-model="indicationSelected"
                :disabled="disableFields"
              >
                <option
                  v-for="(item, index) in indication"
                  :value="item.value"
                  :key="index"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
          </div>
            <div class="d-flex flex-column col-12">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="sales_indication">Nome Indicação</label>
              </div>
              <div class="form-group col-lg-12">
                <input
                  v-model="opportunitie.extraDatas.sales_person_name"
                  type="text"
                  name="sales_indication"
                  id="sales_indication"
                  class="form-control"
                  placeholder="Nome de quem indicou"
                  :disabled="disableFields"
                />
              </div>
            </div>
            <div class="d-flex flex-column col-12">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="email">Email Indicação</label>
              </div>
              <div class="form-group col-lg-12">
                <input
                  @change="validateEmail($event)"
                  @blur="validateEmail($event)"
                  v-model="opportunitie.extraDatas.sales_person_email"
                  type="email"
                  name="sales_email"
                  id="sales_email"
                  class="form-control"
                  placeholder="email@email.com"
                  :disabled="disableFields"
                />
              </div>
            </div>

            <div class="d-flex flex-column col-12">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="cellphone"
                  >Telefone/Celular Indicação</label
                >
              </div>
              <div class="form-group col-lg-12">
                <vue-tel-input
                    @country-changed="setSalesPersonPhonePrefix($event)"
                    @validate="SalesPersonPhoneIsValid($event)"
                    defaultCountry="BR"
                    v-model="opportunitie.extraDatas.sales_person_phone"
                    class="form-control"
                    :class="!disableFields ? SalesPersonPhoneValid ? 'number-valid' : 'number-invalid' : ''"
                    :inputOptions="{
                      placeholder: 'Telefone da indicação',
                    }"
                    :dropdownOptions="{
                      showFlags: !disableFields
                    }"
                    :disabled="disableFields"
                  >
                    <template v-slot:arrow-icon>
                      <span>{{ '▼' }}</span>
                    </template>
                  </vue-tel-input>
              </div>
            </div>
            <div class="d-flex flex-column col-12 mt-4">
              <div class="label d-flex justify-content-start">
                <label class="word-custom" for="plan">5. Plano</label>
              </div>
              <div class="form-group col-lg-12">
                <input
                  type="text"
                  id="plan"
                  name="plan"
                  class="form-control"
                  placeholder="Perfil"
                  v-model="planSelected"
                  :disabled="disableFields"
                  v-if="this.$store.state.proposalData || this.$store.state.admin.perfil === 'admin'"
                >
                <select
                  type="text"
                  id="plan"
                  name="plan"
                  class="form-control"
                  placeholder="Perfil"
                  v-model="planSelected"
                  :disabled="disableFields"
                  v-else
                >
                  <option
                    v-for="(item, index) in plans"
                    :value="item.value"
                    :key="index"
                  >
                    {{ item.label }}
                  </option>
                </select>
                <div class="d-flex flex-column col-12 mt-2">
                <span class="m-2" v-if="planSelected !== ''" style="color:#339665"> {{ ` O plano escolhido possui ${opportunitie.extraDatas.users} usuários. Caso queira incluir novos usuários, selecione usuários adicionais.` }}</span>
            </div>
              </div>
              <div class="form-check mt-3 ml-5 mb-4">
              <div class="d-flex justify-content-start">
                <div class="form-radio-group col-lg-3">
                  <input
                    class="form-check-input"
                    id="monthly"
                    type="checkbox"
                    ref="montlyCheck"
                    v-model="opportunitie.extraDatas.montly"
                    @click="changePlanType()"
                    :disabled="disableFields
                    "
                  />
                  <label class="form-check-label"> Mensal</label>
                </div>
                <div class="form-radio-group col-lg-3">
                  <input
                    v-model="opportunitie.extraDatas.yearly"
                    class="form-check-input"
                    id="yearly"
                    type="checkbox"
                    @click="changePlanType()"
                    :disabled="disableFields
                    "
                  />
                  <label class="form-check-label"> Anual</label>
                </div>
              </div>
            </div>  
           
            <div class="d-flex flex-column col-12 mt-2">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="message-pack"> 6. Mensageria</label>
              </div>

            <div class="d-flex col-12 mt-2 p-1 ml-5">
            <div class="form-check">
                 <input v-model="selectedMessage" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="tallos" :checked="selectedMessage" :disabled="disableFields">
                 <label class="form-check-label" for="flexRadioDefault1">
                     TALLOS
                   </label>
              </div>
            <div class="form-check ml-5">
              <input v-model="selectedMessage" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="broker" :disabled="disableFields">
              <label class="form-check-label" for="flexRadioDefault2">
                Broker
              </label>
            
            </div>
          </div>
          <span v-if="selectedMessage=== 'broker'" style="color:#339665">A contratação de mensageria será responsabilidade do cliente.</span>
          <span class="m-2" v-else="selectedMessage === 'tallos'" style="color:#339665"> {{ `A contratação contempla 1000 conversas bônus. ` }}</span>          
        </div> 
          
          <div
          v-if="selectedMessage === 'tallos'"
              class="d-flex flex-column col-12 mt-4"
            >
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="coupon">Pacote de conversas</label>
              </div>
              <div class="form-group col-lg-12">
                <input
                  type="text"
                  id="package"
                  name="package"
                  class="form-control"
                  placeholder="Escolha o valor do pacote"
                  :disabled="disableFields"
                  v-model="typingValue"
                  @keypress="Typing(), isNumber($event)"
                />
                <div class="listAddons" v-if="isTyping">
                  <option
                    v-for="(item, index) in addons"
                    :value="addons"
                    :key="index"
                    class="addonValue"
                    @click="
                      typingValue = `${addons[index].qty_messages}`,
                      isTyping = false,
                        messengerPack(item)
                    "
                  >
                    {{ addons[index].qty_messages }}
                  </option>
                </div>
              </div>
              <div v-if="selectedMessage === 'tallos'" class="d-flex flex-column col-12 mt-4">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="coupon"
                  >Cupom de desconto do plano</label
                >
              </div>
              <div class="form-group col-lg-8">
                <input
                  v-model="opportunitie.subscription.coupon.id"
                  type="text"
                  id="coupon"
                  name="coupon"
                  class="form-control"
                  placeholder="MEUCUPOM"
                  @blur="checkCoupon($event)"
                  :disabled="disableFields"
                  />
              </div>
              <span class="m-2" v-if="coupon_validate.valid === true" style="color:#339665"> {{ ` Desconto de ${offPlan}% aplicado!` }}</span>
              <span class="m-2" v-if="coupon_validate.valid === false" style="color: #ff5154"> {{ ` Cupom inválido! ` }}</span>
            </div>
           

          </div>
          <div  class="d-flex flex-column col-12 mt-4">
              <div class="label d-flex justify-content-start">
                <label class="word-custom" for="addon_chip_selected" >7. Chips adicionais</label>
              </div>
              <div class="form-group col-lg-12">
                <input
                  type="text"
                  id="addon_chip_selected"
                  name="addon_chip_selected"
                  class="form-control"
                  placeholder=""
                  v-model="addon_chip_selected"
                  :disabled="not_extra_chips || disableFields"
                  v-if="this.$store.state.proposalData || this.$store.state.admin.perfil === 'admin'"
                >
                <select
                  type="text"
                  id="addon_chip_selected"
                  name="addon_chip_selected"
                  class="form-control"
                  placeholder=""
                  v-model="addon_chip_selected"
                  :disabled="not_extra_chips || disableFields"
                  v-else
                >
                  <option
                    :value="item"
                    v-for="(item, index) in addon_chip"
                    :key="index"
                  >
                    {{ item.sale_item.name}}
                  </option>
                </select>
                <div class="mt-2 p-2">
              <input v-model="not_extra_chips" type="checkbox"> Não desejo chips adicionais
                  </div>
              </div>
            </div>
          <div v-if="selectedMessage === 'broker'" class="d-flex flex-column col-12 mt-4">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="coupon"
                  >Cupom de desconto do plano</label
                >
                
              </div>
              <div class="form-group col-lg-8">
                <input
                  v-model="opportunitie.subscription.coupon.id"
                  type="text"
                  id="coupon"
                  name="coupon"
                  class="form-control"
                  placeholder="MEUCUPOM"
                  @blur="checkCoupon($event)"
                  :disabled="disableFields"
                  />
              </div>
              <span class="m-2" v-if="coupon_validate.valid === true" style="color:#339665"> {{ ` Desconto de ${offPlan}% aplicado!` }}</span>
              <span class="m-2" v-if="coupon_validate.valid === false" style="color: #ff5154"> {{ ` Cupom inválido! ` }}</span>
            </div>

          <div class="d-flex flex-column col-12 mt-2">
            <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="coupon-chips">  Cupom para contratação de chips adicionais </label>
              </div>
            <div class="form-group col-lg-8">

                <input
                  :disabled="not_extra_chips === true || disableFields"
                  type="text"
                  id="coupon-chips"
                  name="coupon-chips"
                  class="form-control"
                  placeholder="CUPOM CHIPS"
                  ref="chipCupons"
                  v-model="coupon_chip"
                  @blur="checkCouponAddonChip($event)"
                  />
              </div>
              <span class="m-2" v-if="coupon_validate_addon_chip.valid === true" style="color:#339665"> {{ `  Cupom de ${this.coupon_validate_addon_chip.coupon.value} % desconto adicionado!` }}</span>
              <span class="m-2" v-if="coupon_validate_addon_chip.valid === false" style="color: #ff5154"> {{ ` Cupom inválido! ` }}</span>
            </div>
            </div>
            <div class="d-flex flex-column col-12 mt-2">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="users"> 8. Usuários adicionais</label>
              </div>
              <div class="form-group col-lg-12">
                <input
                  @change="validateMaxUsers($event)"
                  :min="minUsers"
                  :max="maxUsers"
                  :maxlength="maxUsers"
                  v-model="previous_users"
                  @keypress="isNumber($event)"
                  type="number"
                  id="extra-users"
                  name="extra-users"
                  class="form-control"
                  placeholder="quantidade de usuarios"
                  :disabled="disableFields"
                />
              </div>
            </div> 
            <div class="d-flex flex-column col-12 mt-2">
            <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="users">  Cupom para contratação de usuários adicionais </label>
              </div>
            <div class="form-group col-lg-12">

                <input
                  type="text"
                  id="coupon-users"
                  name="coupon-users"
                  ref="couponUsers"
                  class="form-control"
                  placeholder="CUPOM USUARIOS"
                  :disabled="disableFields"
                  v-model="coupon_user"
                  @blur="checkCouponAddonUser($event)"
                  />
              </div>
              <span class="m-2" v-if="coupon_validate_addon_user.valid === true" style="color:#339665"> {{ `  Cupom de ${this.coupon_validate_addon_user.coupon.value} % desconto adicionado!` }}</span>
              <span class="m-2" v-if="coupon_validate_addon_user.valid === false" style="color: #ff5154"> {{ ` Cupom inválido! ` }}</span>
            </div>
       
     
            <div class="d-flex flex-column col-12 mt-2">
              <div class="label d-flex justify-content-start">
                <label class="word-custom" for="plan">9. Serviços</label>
              </div>
              <div class="form-check-group col-lg-6 ml-4">
                <input
                  v-model="implantation"
                  class="form-check-input"
                  ref="implatation"
                  id="addon"
                  type="checkbox"
                  name="implantation_type"
                  value="implantation"
                  :disabled="disableFields"
                />
                <label class="form-check-label" for="addon">
                  Implantação
                </label>
              </div>
              <div class="form-check-group col-lg-6 ml-4">
                <input
                  v-model="implantation_tec"
                  class="form-check-input"
                  id="addon2"
                  type="checkbox"
                  name="implantation_type"
                  value="implantation-tec"
                  :disabled="disableFields"
                />
                <label class="form-check-label" for="addon2">
                  Implantação Técnica
                </label>
              </div>
            </div>

            <div class="d-flex flex-column col-12 mt-4">
              <div class="label d-flex justify-content-start col-lg-12">
                <label class="word-custom" for="coupon"
                  >Cupom de desconto nos serviços</label
                >
              </div>
              <div class="form-group col-lg-12">
                <input
                  v-model="coupoun_addons.id"
                  type="text"
                  id="coupon"
                  name="coupon"
                  class="form-control"
                  placeholder="MEUCUPOM"
                  :disabled="disableFields"
                  @blur="checkCouponAddon($event)"
                />
                <span class="m-2" v-if="coupon_validate_addons.valid === true" style="color:#339665"> {{ `Desconto de ${this.offAddon}% aplicado!` }}</span>
              <span class="m-2" v-if="coupon_validate_addons.valid === false" style="color: #ff5154"> {{ `${coupon_validate_addons.reason} ` }}</span>
              </div>
            </div>
          </div>

          <hr />
          <div
            class="col-lg-12 d-flex justify-content-center"
            v-if="cancelOportunitie === false"
          >
            <button
              class="btn-big btn btn-primary ml-3"
              data-toggle="modal" data-target="#exampleModalLong"
              @click="getPlan()"
            >
              Cadastrar
            </button>
          </div>
        </section>
        <div class="d-flex justify-content-center">
          <div
            v-if="
              cancelOportunitie === true &&
                this.$store.state.admin.perfil !== 'admin'
            "
          >
            <button
              class="btn-big btn btn-outline-secondary ml-3"
              @click="returnPage()"
            >
              Voltar
            </button>
          </div>
          <div
            v-if="
              cancelOportunitie === true &&
                this.$store.state.admin.perfil !== 'admin' &&
                  this.$store.state.proposalData.seller_email === this.$store.state.admin.email
            "
          >
            <button
              class="btn-big btn btn-danger ml-3"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              data-bs-whatever="@fat"
              :disabled="disableCancel"
            >
              Cancelar proposta
            </button>
          </div>
        </div>
      </div>
    </div>
    </form>
  </div>
  <!-- /.content-wrapper -->
</template>

<script>
import TitlePage from "../../../shared/components/title-page/TitlePage.vue";
import ChannelSelect from "../components/SaleChannel/selectChannel.vue"
import SummaryModal from "../components/Summary/Summary.vue"
import midBillign from "../../../../services/proposalsApi";
import route from "../../../../router/router";
import axios from "axios";
import billingAddonsPackage from "../../../../services/packagesApi.js";
import * as lodash from 'lodash'
import { ProgramKeys } from "../../../../shared/domain/enum/program-keys-partners.enum"

export default {
  components: {
    TitlePage,
    SummaryModal,
    ChannelSelect
  },
  computed:{
    disableZipCode() {
      return this.disableFields || (this.cnpjIsValid && !!this.cnpjAddress.zip_code)
    },
    disableDistrict() {
      return this.disableFields || (this.cnpjIsValid && !!this.cnpjAddress.district)
    },
    disableStreet() {
      return this.disableFields || (this.cnpjIsValid && !!this.cnpjAddress.street)
    },
    disableNumber() {
      return this.disableFields || (this.cnpjIsValid && !!this.cnpjAddress.number)
    },
    disableComplement() {
      return this.disableFields || (this.cnpjIsValid && !!this.cnpjAddress.complement)
    },
    disableCity() {
      return this.disableFields || (this.cnpjIsValid && !!this.cnpjAddress.city)
    },
    disableState() {
      return this.disableFields || (this.cnpjIsValid && !!this.cnpjAddress.state)
    },
    validateCouponPlan() {
      return !(this.coupon_validate.valid === false || this.opportunitie.subscription.coupon.id === '')
    },
    validateCouponAddon() {
      return !(this.coupon_validate_addons.valid === false || this.coupoun_addons.id === '')
    },
    asChips() {
      return this.addon_chip_selected?.sale_item?.name ? this.addon_chip_selected.sale_item.name : ""
    },
    rulesAssociatedSales(){
      return (this.$store.state.admin?.associated_seller === true && this.$store.state.admin?.perfil === 'seller' || this.$store.state.admin?.perfil === 'admin' )
    },
    associateSaleSelected(){
      return this.opportunitie.sales_channel === 'associated'
    },
  },
  watch:{
    cnpjAddress: {
      handler: function (val) {
        this.opportunitie.address.street = val.street
        this.opportunitie.address.district = val.district
        this.opportunitie.address.zip_code = val.zip_code
        this.opportunitie.address.number = val.number
        this.opportunitie.address.complement = val.complement
        this.opportunitie.address.city = val.city
        this.opportunitie.address.state = val.state
      },
      deep: true
    },
    planSelected(value){
      let strategy = {
        'essential': 2,
        'advanced': 6,
        'professional': 10,
      }
      this.opportunitie.extraDatas.users = strategy[value]
    },
    previous_users(value) {
      if(value > 1000){
        this.previous_users = 1000;
      }
      this.selectQuantityUsers(value)
    },
    not_extra_chips(value) {
      value === true ? this.addon_chip_selected = {} : this.getBillingChip();
    },
    partnerFilter(newFilter) {
      if(newFilter === ""){
        this.opportunitie.partner = {}
      }
    },
    partnerIndicationSelected() {
      this.opportunitie.partner_program = '';
      this.opportunitie.partner = '';
      this.partnerFilter = '';
      this.indicationSelected = '';
      this.indicationPartner = []
    }    
  },
  data() {
    return {
      partnerFilter: '',
      countryPrefix: '55',
      phonePrefix: '55',
      FocalPhoneFinancePrefix: '55',
      FocalPhoneProjectPrefix: '55',
      SalesPersonPhonePrefix: '55',
      countryValid: false,
      phoneValid: false,
      FocalPhoneFinanceValid: false,
      FocalPhoneProjectValid: false,
      SalesPersonPhoneValid: false,
      coupon_user: '',
      coupon_chip: '',
      not_extra_chips: false,
      selectedMessage: "tallos",
      pacakgeMessage: "",
      cnpjIsValid: false,
      modalOpen: false,
      completeAddons: [],
      addons: [],
      typingValue: "",
      isTyping: false,
      isTypingPartner: false,
      openIntegrationInput: false,
      disableCancel: false,
      disableFields: false,
      cancelOportunitie: false,
      apiProposal: new midBillign(),
      apiPackagesAddons: new billingAddonsPackage(),
      titlePage: "Oportunidades",
      SummaryModal: {
        title:"Confirmar Proposta",
        subtitle: `Após a confirmação, um email para visualização da proposta será enviado para `,
      },
      iconClass: "fa fa-clipboard-list ",
      plans: [
        {
          label: "Light",
          value: "essential",
        },
        {
          label: "Basic",
          value: "advanced",
        },
        {
          label: "Pro",
          value: "professional",
        },
      ],
      previous_users: '',
      planSelected: "",
      indicationSelected: "",
      parterSelected: {},
      indicationPartner: [],
      indication: [
        {
          label: "Sem indicação",
          value: "",
        },
        {

          label: "TOTVS",
          value: "TOTVS",
        },
        {
          label: "RD",
          value: "RD",
        },
        {
          label: "Agências",
          value: "Agências",
        },
        {
          label: "Indicações de agências",
          value: "Indicações de agências",
        },
      ],
      partnerIndication: [
        {
          label: "Selecione o canal de revenda",
          value: "",
        },
        {

          label: "TOTVS",
          value: "TOTVS",
        },
        {
          label: "Programa de parcerias RD",
          value: "RD",
        }
      ],
      partnerIndicationSelected: "",
      selectTypePayment: [
        {
          interval_unit: "month",
          interval_length: 1,
          quantity: 12,
        },
        {
          interval_unit: "month",
          interval_length: 1,
          quantity: 1,
        },
      ],
      minUsers: 0,
      maxUsers: 1000,
      implantation_tec: false,
      implantation: true,
      billingOnboard: [],
      opportunitie: {
        partner:{
        company_id: '',
        country: '',
        created_at:'',
        id: 0,
        internal: false,
        locale: '',
        name: '',
        programs: {}
        },
        represent_legal_name: "",
        seller_email: "",
        seller_id: "",
        isSeller: true,
        name: "",
        legal_name: "",
        locale: "pt-BR",
        person_type: "legal_entity",
        document: "",
        address: {
          street: "",
          district: "",
          zip_code: "",
          number: "",
          complement: "",
          city: "",
          state: "",
          country_code: "BR",
          phone: "",
        },
        email: "",
        kind: "admin",
        phone: "",
        mobile: "",
        jobTitle: "Engineer",
        subscription: {
          id_customer: null,
          seller_name: "",
          sale_item: {
            id: "",
            name: "",
          },
          billing_config: {
            interval_unit: "",
            interval_length: 0,
            quantity: 0,
          },
          payment_config: {
            expiration_day: 0,
            payment_mode: "manual",
            payments_net_term_days: 0,
          },
          coupon: {
            id: "",
          },
          addons: [],
        },
        proposal_status: "",
        extraDatas: {
          focal_name: "",
          focal_phone: "",
          focal_email_finance: "",
          focal_phone_finance: "",
          focal_name_project: "",
          focal_phone_project: "",
          focal_email_project: "",
          montly: true,
          yearly: false,
          users: 1,
          sales_indication: "", 
          sales_person_name: "",
          sales_person_email: "",
          sales_person_phone: ""
        },
        purchase_date: "",
        sales_channel: "TALLOS",
        partner_program: "",  
      },
      code: "",
      coupoun_addons: {
        id: "",
      },
      defaultPackageMsg: {},
      coupon_validate:{},
      coupon_validate_addons:{},
      coupon_validate_addon_user: {},
      coupon_validate_addon_chip: {},
      users_addon: [],
      users_qty_selected: {},
      addon_chip: [],
      addon_chip_selected: {},
      offAddon: null,
      offPlan: null,
      cnpjAddress: {
        zip_code: '',
        number: '',
        street: '',
        district: '',
        city: '',
        state: '',
        complement: '',
      },
    };
  },

  methods: {
    selectChannel(channel){
      this.opportunitie.sales_channel = channel
    },
    countryIsValid(event){
      this.countryValid = event?.valid
    },
    PhoneIsValid(event){
      this.phoneValid = event?.valid
    },
    FocalPhoneFinanceIsValid(event){
      this.FocalPhoneFinanceValid = event?.valid
    },
    FocalPhoneProjectIsValid(event){
      this.FocalPhoneProjectValid = event?.valid
    },
    SalesPersonPhoneIsValid(event){
      this.SalesPersonPhoneValid = event?.valid
    },
    getCountry(event){
      this.opportunitie.address.country_code = event.iso2
      this.countryPrefix = event.dialCode 
    },
    setPhonePrefix(event){
      this.phonePrefix = event.dialCode 
    },
    setFocalPhoneFinancePrefix(event){
      this.FocalPhoneFinancePrefix = event.dialCode 
    },
    setFocalPhoneProjectPrefix(event){
      this.FocalPhoneProjectPrefix = event.dialCode
    },
    setSalesPersonPhonePrefix(event){
      this.SalesPersonPhonePrefix = event.dialCode
    },
    changePlanType() {
      if (this.opportunitie.extraDatas.montly === true)  {
            this.opportunitie.extraDatas.montly = false
      }
      else {
            this.opportunitie.extraDatas.montly = true
      }
      if (this.opportunitie.extraDatas.yearly === true){
            this.opportunitie.extraDatas.yearly = false
      }
      else {
            this.opportunitie.extraDatas.yearly = true
      } 
    },
    selectPartner(value){
      this.opportunitie.partner = value;
      this.partnerFilter = value.name;
      this.isTypingPartner = !this.isTypingPartner;
      this.indicationSelected = value.name;
      this.filterProgram(value)
      return value
    },
    filterProgram(obj) {
      const programNames = Object.values(ProgramKeys);
      const { programs } = obj;

      //valida se e pre-parceiro
      if(JSON.stringify(programs) === '{}' && this.partnerIndicationSelected === 'RD'){
        this.opportunitie.partner_program = ''
      }
      else {
        const activeProgram = Object.keys(programs).find(programKey => {
          const program = programs[programKey];
          //caso o programa seja da rd ele não precisa estar ativo, so não pode ser os programas da totvs
          if(this.partnerIndicationSelected === 'RD'){
            const isValidProgram = !programNames.includes(programKey);
            return isValidProgram;
          }
          else {
            const isActive = program.status === "active";
            const isValidProgram = programNames.includes(programKey);
            return isActive && isValidProgram;
          }
        });
        this.opportunitie.partner_program = activeProgram || null;
        if(!this.opportunitie.partner_program){
          this.opportunitie.partner_program = '';
          this.opportunitie.partner = '';
          this.partnerFilter = '';
          this.indicationSelected = '';
          let errorMsg = 'este parceiro não está ativo nos programas de revenda permitidos como Venda Associada.'
          if(this.partnerIndicationSelected === 'RD'){
            errorMsg = 'este parceiro não permite revenda por este canal. Por favor, verifique canal escolhido.'
          }
          this.$toasted.global.defaultError({
            msg: errorMsg,
          });
        }
      }
    },
    returnPage() {
      route.back()
    },

    async checkCoupon(event) {
      if (!this.planSelected) {
        this.$toasted.global.defaultError({
          msg: `Para cadastrar um cupom você precisa escolher um plano e a quantidade de usuários.`,
        });
        return
      } else {
        let saleItem = "conversational"
        let couponName = event.target.value
        let off_plan = ""
        if (this.selectedMessage === 'broker') {
          saleItem = `${saleItem}-externo-${this.planSelected}-0-2025`;
        }
        
        if (this.selectedMessage === 'tallos') {
          let formatedTypingValue = this.typingValue / 1000;
          saleItem = `${saleItem}-interno-${this.planSelected}-${formatedTypingValue}-2025`;
        }
        let response = await this.apiPackagesAddons.validateCoupon(saleItem, couponName)
        if (response.data.valid === true) {
          off_plan = response.data.coupon.value
          this.offPlan = parseFloat(off_plan) * 100;
        }else{
          this.opportunitie.subscription.coupon.id = ''
          this.offPlan = 0
          this.$toasted.global.defaultError({
          msg: `Cupom inválido`,
        });
        }
        this.coupon_validate = response.data
        return response
      }
    },

    async checkCouponAddon(event){
      if (!this.implantation && !this.implantation_tec) {
        this.$toasted.global.defaultError({
          msg: `Para cadastrar um cupom você precisa escolher uma implantação.`,
        });
      }
      let addonImplantation = this.billingOnboard[0].sale_item.id;
      let addonImplantationTec = this.billingOnboard[1].sale_item.id;
      let couponNamed = event.target.value
      let response = {};
      let off_addon = null;

      if(this.implantation === true && this.implantation_tec === false){
        response = await this.apiPackagesAddons.validateCoupon(addonImplantation, couponNamed)
      }
      if(this.implantation_tec === true){
        response = await this.apiPackagesAddons.validateCoupon(addonImplantationTec, couponNamed)
      }
      //conversao do desconto para tela mostrar em percentagem.
      if (response.data.valid === true) {
        off_addon = response.data.coupon.value
        this.offAddon = parseFloat(off_addon) * 100;
      }else{
        this.coupoun_addons.id = ''
          this.$toasted.global.defaultError({
          msg: `Cupom inválido`,
        });
        }

      this.coupon_validate_addons = response.data;
    },
    async checkCouponAddonUser(event) {
      let couponValue = event.target.value
      let addonUser = this.users_qty_selected.sale_item.id;
      let response = {};
      response = await this.apiPackagesAddons.validateCoupon(addonUser, couponValue)
      this.coupon_validate_addon_user = response.data
      if(this.coupon_validate_addon_user.valid === true) {
        this.users_qty_selected.coupon.id = response.data.coupon.code
        this.coupon_validate_addon_user.coupon.value = response.data.coupon.value * 100;
      }else{
          this.coupon_user = ''
          this.$toasted.global.defaultError({
          msg: `Cupom inválido`,
        });
        }
      return response
    },
    async checkCouponAddonChip(event){
      if (Object.keys(this.addon_chip_selected) == '') {
        this.$toasted.global.defaultError({
          msg: `Para cadastrar um cupom você precisa escolher pelo menos 1 chip adicional.`,
        });
        return
      }
      let couponValue = event.target.value
      let addonChip = this.addon_chip_selected.sale_item.id;
      let response = {};
      response = await this.apiPackagesAddons.validateCoupon(addonChip, couponValue)
      this.coupon_validate_addon_chip = response.data
      if(this.coupon_validate_addon_chip.valid === true) {
        this.addon_chip_selected.coupon.id = response.data.coupon.code
        this.coupon_validate_addon_chip.coupon.value = response.data.coupon.value * 100;
      }else{
        this.coupon_chip = ''
        this.$toasted.global.defaultError({
          msg: `Cupom inválido`,
        });
      }
      return response
    },
    filterAddons(addonsData = this.completeAddons, typing = this.typingValue, limit = 7) {
      typing = Number(typing)
      let filteredAddon = []
      addonsData.sort((a, b) => {
        return a.qty_messages - b.qty_messages
      })
      for (const key in addonsData) {
        if (addonsData[key].qty_messages >= typing){
          filteredAddon.push(addonsData[key])

          if (filteredAddon.length > limit - 1){
            this.addons = filteredAddon
            break
          }
        }
      }
    },
    messengerPack(value) {
      this.pacakgeMessage = value.qty_messages
    },

    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async getPaymentSettings() {
      this.$tallos_admin_api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.$store.getters.tokenJWT}`;
      const response = await this.$tallos_admin_api.get(`settings`);
      this.opportunitie.subscription.payment_config.payments_net_term_days =
        response.data.payments_net_term_days;
    },
    validateEmail(event) {
      let email = event.target.value
      const emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z]+(?:\.[a-z]+)?$/i;
      if (!emailRegex.test(email)) {
        event.target.value = ''
        this.$toasted.global.defaultError({
          msg: `Por favor, insira um e-mail válido`,
        });
      }
    },
    async searchAdress() {
      try {
        let formartCep = this.opportunitie.address.zip_code.replace(
          /\.|\-/g,
          ""
        );
        let response = await axios.get(
          `https://viacep.com.br/ws/${formartCep}/json/`
        );
        this.opportunitie.address.street = response.data.logradouro;
        this.opportunitie.address.district = response.data.bairro;
        this.opportunitie.address.city = response.data.localidade;
        this.opportunitie.address.state = response.data.uf;
        this.opportunitie.address.complement = response.data.complemento;
        return response;
      } catch (error) {
        this.$toasted.global.defaultError({
          msg: `Por favor, insira um cep válido`,
        });
      }
    },
    async createProposal() {
      try {
        if (this.opportunitie.name) {
          this.opportunitie.name = this.opportunitie.name.trim();
        }
        if (this.opportunitie.legal_name) {
          this.opportunitie.legal_name = this.opportunitie.legal_name.trim();
        }
        if (this.opportunitie.represent_legal_name) {
          this.opportunitie.represent_legal_name = this.opportunitie.represent_legal_name.trim();
        }
        if (this.opportunitie.extraDatas.focal_name) {
          this.opportunitie.extraDatas.focal_name = this.opportunitie.extraDatas.focal_name.trim();
        }
        if (this.opportunitie.extraDatas.focal_name_project) {
          this.opportunitie.extraDatas.focal_name_project = this.opportunitie.extraDatas.focal_name_project.trim();
        }
        const requiredFields = [
          "name",
          "document",
          "email",
          "address",
          "phone",
          "mobile",
          "subscription",
          "represent_legal_name",
          "extraDatas"
        ];

        const extraFields = [
          "focal_name",
          "focal_email_finance",
          "focal_phone_finance",
          "focal_name_project",
          "focal_phone_project",
          "focal_email_project"
        ]

        for (const field of requiredFields) {
          let fieldName = {
          "name" : "Nome da Empresa",
          "document" : "CNPJ",
          "email" : "Email",
          "address" : "Endereço",
          "phone" : "Telefone",
          "mobile" : "Telefone",
          "subscription" : "Plano",
          "represent_legal_name": "Nome do reprensentante legal"
          }

          let extraFieldsName = {
            "focal_name": "Nome Ponto focal financeiro",
            "focal_email_finance": "Email do ponto focal do financeiro",
            "focal_phone_finance": "Telefone/ Whatsapp do Ponto focal financeiro",
            "focal_name_project": "Nome Ponto focal projeto",
            "focal_phone_project": "Telefone/ Whatsapp do Ponto focal projeto",
            "focal_email_project": "Email do ponto focal do projeto"
          }

          if (field === "extraDatas") {
            for (const extra of extraFields) {
              if(!this.opportunitie.extraDatas[extra]){
                this.$toasted.global.defaultError({ msg: `${extraFieldsName[extra]} inválido!` });
              }
            }
          }
          else if (!this.opportunitie[field]) {
            this.$toasted.global.defaultError({ msg: `${fieldName[field]} inválido!` });
          }
        }
        //caso os cupons de proposta ou serviço sejam invalidos, ele não irá atribuir valor na criação de proposta no banco.
        if (this.coupon_validate.valid === false) {
          this.opportunitie.subscription.coupon.id = ""
        }
        if (this.coupon_validate_addons.valid === false) {
          this.coupoun_addons.id = "";
        }
       
        if (Object.keys(this.users_qty_selected).length !== 0) {
          const index = this.opportunitie.subscription.addons.findIndex(
            (addon) => addon.qty_users === this.users_qty_selected.qty_users
          );

          if (index === -1) {
            this.opportunitie.subscription.addons.push(this.users_qty_selected);
          } else {
            this.opportunitie.subscription.addons[index] = this.users_qty_selected;
          }
        }

        if (Object.keys(this.addon_chip_selected).length !== 0) {
          const indexChip = this.opportunitie.subscription.addons.findIndex(
            (addon) => addon.value === this.addon_chip_selected.value
          );

          if (indexChip === -1) {
            this.opportunitie.subscription.addons.push(this.addon_chip_selected);
          } else {
            this.opportunitie.subscription.addons[indexChip] = this.addon_chip_selected;
          }
        }
      
        this.opportunitie.extraDatas.yearly == true
          ? (this.opportunitie.subscription.billing_config =
           this.selectTypePayment[0])
          : (this.opportunitie.subscription.billing_config = 
          this.selectTypePayment[1]);

          if(!this.planSelected){
              this.$toasted.global.defaultError({ msg: `Plano inválido!` });
            return
          }
          const strategy = {
            'essential': 'Light',
            'advanced': 'Basic',
            'professional': 'Pro',
          };

          let baseId;
          let baseName;

          if (this.selectedMessage === 'broker') {
            baseId = `externo-${this.planSelected}-0-2025`;
            baseName = `${strategy[this.planSelected]} (2025)`;
          } else if (this.selectedMessage === 'tallos') {
            if (this.typingValue === '') {
              this.$toasted.global.defaultError({
                msg: `Selecione o pacote de conversas.`,
              });
              return;
            }
            const formatedTypingValue = this.typingValue / 1000;
            const conversational = parseFloat(this.typingValue);
            baseId = `interno-${this.planSelected}-${formatedTypingValue}-2025`;
            baseName = `${strategy[this.planSelected]} com até ${conversational.toLocaleString('pt-BR')} Conversas (2025)`;
          }

          if (baseId && baseName) {
            const saleItem = {
              id: `conversational-${baseId}`,
              name: `RD Conversas Plano ${baseName}`,
            };
            this.opportunitie.subscription.sale_item = saleItem;
          }


        if(this.selectedMessage === ''){
          this.$toasted.global.defaultError({ msg: 'Escolha o responsável pela contratação de mensageria' });
          return
        }

          this.opportunitie.extraDatas.sales_indication = this.indicationSelected;
        const date = new Date();
        this.opportunitie.subscription.payment_config = {
          expiration_day: Number(date.getDate()),
          payment_mode: this.opportunitie.subscription.payment_mode,
          payments_net_term_days: this.opportunitie.subscription.payment_config
            .payments_net_term_days,
          };
          this.opportunitie.seller_id = this.$store.getters.admin.email;
          this.opportunitie.subscription.billing_config.email_customer = this.$store.getters.admin.email;
          this.opportunitie.seller_email = this.$store.state.admin.email
          this.opportunitie.associated_seller_email = this.$store.state.admin.associated_seller_email
          
          this.opportunitie.mobile = this.addPrefixIfNotExists(this.opportunitie.mobile, this.countryPrefix)
          this.opportunitie.phone = this.addPrefixIfNotExists(this.opportunitie.phone, this.phonePrefix)
          this.opportunitie.address.phone = this.opportunitie.phone;
          this.opportunitie.extraDatas.focal_phone_finance = this.addPrefixIfNotExists(this.opportunitie.extraDatas.focal_phone_finance, this.FocalPhoneFinancePrefix);
          this.opportunitie.extraDatas.focal_phasone_project = this.addPrefixIfNotExists(this.opportunitie.extraDatas.focal_phone_project, this.FocalPhoneProjectPrefix);
          this.opportunitie.extraDatas.sales_person_phone = this.addPrefixIfNotExists(this.opportunitie.extraDatas.sales_person_phone, this.SalesPersonPhonePrefix);
          this.opportunitie.extraDatas.focal_phone = this.opportunitie.extraDatas.focal_phone_project;
        
        if (this.opportunitie.sales_channel === "associated") {
          let partnerProgramIsEmpty = !this.opportunitie.partner_program.trim();

          // como na rd existem pre-parceiros, então esse campo pode estar vazio
          if(this.partnerIndicationSelected === 'RD'){
            partnerProgramIsEmpty = false
          }
          const isAssociatedSeller = this.$store.state.admin.associated_seller === true;
          const partnerProgramError = "Uma venda associada deve conter um parceiro que efetuou a indicação. Preencha o parceiro responsável.";
          const permissionError = "Você não tem permissão para fazer uma venda associada. Solicite autorização a um administrador.";
          if (partnerProgramIsEmpty) {
            this.$toasted.global.defaultError({ msg: partnerProgramError });
            return;
          }
          if (!isAssociatedSeller) {
            this.$toasted.global.defaultError({ msg: permissionError });
            return;
          }
        }

        if (this.implantation === true) {
          this.billingOnboard[0].code = this?.code;
          this.billingOnboard[0].coupon.id = this?.coupoun_addons.id;
          this.opportunitie.subscription.addons.push(this.billingOnboard[0]);
        }
        if (this.implantation_tec === true) {
          this.billingOnboard[1].code = this?.code;
          this.billingOnboard[1].coupon.id = this?.coupoun_addons.id;
          this.opportunitie.subscription.addons.push(this.billingOnboard[1]);
        }
        if (this.implantation === false && this.implantation_tec === false) {
          this.$toasted.global.defaultError({
            msg: `Selecione pelo menos uma Implantação!`,
          });
        } else {
          this.$tallos_admin_api.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.$store.getters.tokenJWT}`;
          await this.$tallos_admin_api.post("proposals/new-proposal", {
            ...this.opportunitie,
          }).then(() => {
            this.$toasted.global.defaultSuccess({
              msg: `${this.opportunitie.name} Proposta enviada com sucesso.`,
            });
            this.cleanFields();
          })
        }
        
      } catch (err) {
        this.$toasted.global.defaultError({ msg: err.response.data.message });
        this.opportunitie.subscription.addons = []
      }
    },
    cleanFields() {
        this.opportunitie.address = {}
        this.opportunitie.extraDatas = {}
        this.opportunitie.represent_legal_name = "",
        this.opportunitie.seller_email ="",
        this.opportunitie.seller_id ="",
        this.opportunitie.name ="",
        this.opportunitie.legal_name ="",
        this.opportunitie.document ="",
        this.opportunitie.email ="",
        this.opportunitie.phone ="",
        this.opportunitie.mobile ="",
        this.opportunitie.address.country_code = "BR"
        this.selectedMessage = "tallos"
        this.coupon_validate = {};
        this.users_qty_selected = {}
        this.addon_chip_selected = {}
        this.previous_users = ''
        this.$refs.montlyCheck.checked = true
        this.opportunitie.extraDatas.montly = true
        this.opportunitie.extraDatas.yearly = false
        this.$refs.implatation.checked = true
        this.implantation = true
        this.implantation_tec = false
        this.planSelected = ""
        this.addon_chip = []
        this.opportunitie.subscription.addons = []
        this.opportunitie.sales_channel = "TALLOS";
        this.partnerFilter = ''
        this.isTypingPartner = false
    },
   addPrefixIfNotExists(value, prefix) {
        if (value !== "" && !value.startsWith(prefix)) {
          return `${prefix} ${value}`;
        }
          return value;
        },
    validateMaxUsers(event) {
      event.target.value > 1000
        ? this.$toasted.global.defaultError({
            msg: `Número máximo de usuários é até ${this.maxUsers}`,
          }) && (this.opportunitie.extraDatas.users = this.maxUsers)
        : event.target.value;
    },
    cancelProposal() {
      this.$tallos_admin_api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.$store.getters.tokenJWT}`;
      this.$tallos_admin_api
        .post("proposals/cancelProposal", {
          ...this.$store.state.proposalData,
        })
        .then((res) => {
          this.$toasted.global.defaultSuccess({
            msg: `${this.opportunitie.name} Proposta cancelada com sucesso.`,
          });
          this.disableCancel = true;
        })
        .catch((err) => {
          this.$toasted.global.defaultError({ msg: err.response.data });
        });
    },
    Typing() {
      this.isTyping = true;
      this.addons = [];
      let filterDebounce = lodash.debounce(this.filterAddons, 1000)
      filterDebounce()
    },
    typingPartner() {
      this.isTypingPartner = true;
      let filterDebounce = lodash.debounce(this.getPartnerShip, 1000)
      filterDebounce()
    },
    selectQuantityUsers(value) {
      let valueUsers = Number(value)
      if (valueUsers === 0 || valueUsers === undefined || valueUsers === '') {
        this.users_qty_selected = {}
      } else {
        this.users_qty_selected = this.users_addon.find(element => element.qty_users === valueUsers)
        return this.users_qty_selected
      }
    },
    async getBillingMessage() {
      let msg = await this.apiPackagesAddons.getpackageBillingMessage();
       this.completeAddons = msg.response.data
       return msg
    },
 async getBillingUsersAddon(){
  try{
    let result = await this.apiPackagesAddons.getUsersAddon()
    this.users_addon = result;
    return result
  }catch(error){
    this.$toasted.global.defaultError({ msg: 'Erro ao validar pacote de usuários' });

  }
    },
    async getBillingChip() {
      let chipAddonItems = await this.apiPackagesAddons.getChipAddon();
      this.addon_chip = chipAddonItems.sort((item, addon)=> item.value - addon.value)
      return chipAddonItems
    },
    async getBillingOnboard() {
      let implantation = await this.apiPackagesAddons.getpackageBillingOnboard();
      this.billingOnboard = implantation.response.data.sort((a,b)=> a.value - b.value)
      return implantation
    },
    async getCnpj(cnpj, days) {
      let formatedCNPJ = cnpj.replace(/\D/g,'')
      this.$tallos_admin_api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.$store.getters.tokenJWT}`;
      this.$tallos_admin_api
        .get(`proposals/cnpj/${formatedCNPJ}/${days}`)
        .then((res) => {
        if (res.data.data !== undefined && res.data.data.status === 'OK' && res.data.data.situacao === 'ATIVA') {
          this.$toasted.global.defaultSuccess({
            msg: `CNPJ encontrado com sucesso.`,
          });
            let company = res.data.data
            this.opportunitie.legal_name =  company.nome;
            this.opportunitie.name = company.fantasia ? company.fantasia : company.nome ;
            this.opportunitie.document = company.cnpj;
            this.cnpjAddress.zip_code = company.cep;
            this.cnpjAddress.number = company.numero
            this.cnpjAddress.street = company.logradouro;
            this.cnpjAddress.district = company.bairro;
            this.cnpjAddress.city = company.municipio;
            this.cnpjAddress.state = company.uf;
            this.cnpjAddress.complement = company.complemento;
            this.cnpjIsValid = true
          }
        else {
          this.$toasted.global.defaultError({
          msg: res.data.data && res.data.data.situacao && res.data.data.situacao !== 'ATIVA'? `empresa inativa` : `CNPJ não encontrado.`
          });
          this.opportunitie.document = ""
          this.cnpjIsValid = false
          this.cnpjAddress.zip_code = '';
          this.cnpjAddress.number = ''
          this.cnpjAddress.street = '';
          this.cnpjAddress.district = '';
          this.cnpjAddress.city = '';
          this.cnpjAddress.state = '';
          this.cnpjAddress.complement = '';
        }
        })
    },
    async getPlan(){
      const strategy = {
            'essential': 'Light',
            'advanced': 'Basic',
            'professional': 'Pro',
          };

          let baseId;
          let baseName;

          if (this.selectedMessage === 'broker') {
            baseId = `externo-${this.planSelected}-0-2025`;
            baseName = `${strategy[this.planSelected]} (2025)`;
          } else if (this.selectedMessage === 'tallos') {
            if (this.typingValue === '') {
              this.$toasted.global.defaultError({
                msg: `Selecione o pacote de conversas.`,
              });
              return;
            }
            const formatedTypingValue = this.typingValue / 1000;
            const conversational = parseFloat(this.typingValue);
            baseId = `interno-${this.planSelected}-${formatedTypingValue}-2025`;
            baseName = `${strategy[this.planSelected]} com até ${conversational.toLocaleString('pt-BR')} Conversas (2025)`;
          }

          if (baseId && baseName) {
            const saleItem = {
              id: `conversational-${baseId}`,
              name: `RD Station Conversas Plano ${baseName}`,
            };
            this.opportunitie.subscription.sale_item = saleItem;
          }
          
        this.modalOpen = !this.modalOpen
    },
    async getPartnerShip() {
      this.$tallos_admin_api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.$store.getters.tokenJWT}`;
      
      let queryParams = {
        currentPage: 1,
        perPage: 200,
        customer_name: this.partnerFilter,
      }

      let routerPath = ''
      let objToExtract = 'allPartners'

      if (this.partnerIndicationSelected === 'TOTVS') {
        routerPath = '/partners/getPartnersFranchises'
        let totvsParams = {
          actives: true,
          program_code: 'franquiastotvsvar, oferta-conjunta-reseller-grupo, unidadestotvsvar, oferta-conjunta-reseller-terceiro'
        }

        queryParams = Object.assign(queryParams, totvsParams)
      } else if (this.partnerIndicationSelected === 'RD') {
        if (/^\d+$/.test(this.partnerFilter)) {
          queryParams = {}
          routerPath = `partners/getPartnerById/${this.partnerFilter}`
          objToExtract = 'partnership/partner'
        } else {
          routerPath = '/partners/getPartnersFranchisesRD'
        }
      }

      this.$tallos_admin_api
        .get(routerPath, { params: queryParams }).then((result) => {
          result = result.data
          this.indicationPartner = result.data[objToExtract]
      
          if(objToExtract === 'partnership/partner'){
            this.indicationPartner = [this.indicationPartner]
          }
        })
        .catch((err) => {
          this.$toasted.global.defaultError({ msg: err.response.data.message });
        })
    },
},
  async mounted() {
    this.getBillingUsersAddon();
    this.getPaymentSettings();
    this.getBillingMessage();
    this.getBillingOnboard();
    this.getBillingChip();
    document.title = "Tallos Oportunidades - Cadastrar Oportunidade";

    if (
      this.$store.state.proposalData ||
      this.$store.state.admin.perfil === "admin"
    ) {
      this.disableFields = true;
      this.cancelOportunitie = true;
      let data = this.$store.state.proposalData;
      this.opportunitie.legal_name = data.legal_name;
      this.opportunitie.name = data.name;
      this.opportunitie.document = data.document;
      this.opportunitie.email = data.email;
      this.opportunitie.mobile = data.mobile;
      this.opportunitie.address.zip_code = data.address.zip_code;
      this.opportunitie.address.district = data.address.district;
      this.opportunitie.address.street = data.address.street;
      this.opportunitie.address.number = data.address.number;
      this.opportunitie.address.complement = data.address.complement;
      this.opportunitie.address.city = data.address.city;
      this.opportunitie.address.state = data.address.state;
      this.opportunitie.phone = data.phone;
      this.opportunitie.extraDatas.focal_name = data.extraDatas.focal_name;
      this.opportunitie.extraDatas.focal_phone = data.extraDatas.focal_phone;
      this.opportunitie.extraDatas.focal_phone_finance =
        data.extraDatas.focal_phone_finance;
      this.opportunitie.extraDatas.focal_name_project =
        data.extraDatas.focal_name_project;
      this.opportunitie.extraDatas.focal_phone_project =
        data.extraDatas.focal_phone_project;
      this.opportunitie.extraDatas.yearly = data.extraDatas.yearly;
      this.opportunitie.extraDatas.users = data.extraDatas.users;
      this.opportunitie.subscription.coupon.id = data.subscription.coupon.id;
      this.opportunitie.represent_legal_name = data.represent_legal_name
      this.partnerFilter = data.extraDatas.sales_indication
      this.opportunitie.extraDatas.sales_person_name = data.extraDatas.sales_person_name
      this.opportunitie.extraDatas.focal_email_finance = data.extraDatas.focal_email_finance
      this.opportunitie.extraDatas.focal_email_project = data.extraDatas.focal_email_project
      this.opportunitie.extraDatas.sales_person_email = data.extraDatas.sales_person_email
      this.opportunitie.extraDatas.sales_person_phone = data.extraDatas.sales_person_phone
      this.opportunitie.subscription.coupon.id = data.subscription.coupon.id
      this.opportunitie.sales_channel = data.sales_channel,
      this.data_payment = data.subscription.payment_config.expiration_day;
      let plan = data.subscription.sale_item.name.split(" ");
      if(data.subscription.sale_item.id.split("-")[1] === 'interno'){
        this.selectedMessage = 'tallos'
      }
      else {
        this.selectedMessage = 'broker'
      }
      this.planSelected = plan[2];
      this.typingValue = plan[5]
      data.subscription.addons.forEach((addon) => {
        if(addon.qty_users > 0){
          this.previous_users = addon.qty_users
          this.coupon_user = addon.coupon.id
        }
        else if(addon.sale_item.id.split('-')[1] === 'chips'){
          this.addon_chip_selected = addon.sale_item.name
          this.coupon_chip = addon.coupon.id
        }
        else if(['implantacao', 'implantacaotec'].includes(addon.sale_item.id.split('-')[1])){
          if(addon.sale_item.id.split('-')[1] === 'implantacao'){
            this.implantation = true
          }
          else {
            this.implantation_tec = true
          }
          this.coupoun_addons.id = addon.coupon.id
        }
      })
      if(addon.billing_config.interval_unit = 'month'){
        this.opportunitie.extraDatas.montly = true
      }
      else {
        opportunitie.extraDatas.yearly = true
      }
      if (this.$store.state.proposalData.proposal_status !== "proposal_sent") {
        this.disableCancel = true;
      }
    }
  },
  beforeDestroy() {
    this.$store.state.proposalData = null;
  },
};
</script>

<style scoped>
.content-wrapper {
  background-color: #fff;
}

.btn-big {
  border-radius: 10px;
  width: 180px;
  padding: 5px;
}
.listAddons {
  background-color: white;
  z-index: 2;
  border-right: 1px #4950574d solid;
  border-bottom: 1px #4950574d solid;
  border-left: 1px #4950574d solid;
}
.listPartnes{
  background-color: white;
  z-index: 2;
  border-right: 1px #4950574d solid;
  border-bottom: 1px #4950574d solid;
  border-left: 1px #4950574d solid;
}
.partnersValue:hover {
  background-color: #ced4da;
}
.addonValue:hover {
  background-color: #ced4da;
}
.info-acess{
  font-size: 0.8rem;
}
.number-valid{
  border: green solid 1.5px !important;
}
.number-invalid{
  border: red solid 1.5px !important;
}
.sale-channel{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30%;
  border-radius: 1rem;
  background-color: #78c0df75;

}
.partner-list {
  overflow: auto;
  max-height: 20vh;
}
@media (max-width: 764px) {
  .sale-channel {
    width: 100%;
  }
}
</style>